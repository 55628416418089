<template>
  <v-dialog v-model="show" max-width="1150px" @click:outside="$emit('update:show', false)" @keydown="$emit('update:show', false)">
    <AuditLog
      :logs="logs"
    ></AuditLog>
  </v-dialog>
</template>

<script>
import AuditLog from '@/components/auditLogs/AuditLog.vue'
export default {
  props: {
    show: Boolean,
    logs: Array,
  },
  components: {
    AuditLog,
  }
}
</script>